import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import Clock from '../../../clock'
import rarityTable from "../../../../images/RarityTable.png"
import banner from "../../../../images/StaticCandyBanner.jpeg"
import sample1 from "../../../../images/Sample-1.png"
import sample2 from "../../../../images/Sample-2.png"
import sample3 from "../../../../images/Sample-3.png"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import banner1 from "../../../../images/banner-images/Banner-1.png";
import banner2 from "../../../../images/banner-images/Banner-2.png";
import banner3 from "../../../../images/banner-images/Banner-3.png";
import banner4 from "../../../../images/banner-images/Banner-4.png";
import banner5 from "../../../../images/banner-images/Banner-5.png";
import banner6 from "../../../../images/banner-images/Banner-6.png";
import banner7 from "../../../../images/banner-images/Banner-7.png";
import banner8 from "../../../../images/banner-images/Banner-8.png";
import banner9 from "../../../../images/banner-images/Banner-9.png";
import banner10 from "../../../../images/banner-images/Banner-10.png";
import banner11 from "../../../../images/banner-images/Banner-11.png";
import banner12 from "../../../../images/banner-images/Banner-12.png";
import banner13 from "../../../../images/banner-images/Banner-13.png";
import banner14 from "../../../../images/banner-images/Banner-14.png";
import banner15 from "../../../../images/banner-images/Banner-15.png";
import banner16 from "../../../../images/banner-images/Banner-16.png";
import banner17 from "../../../../images/banner-images/Banner-17.png";
import banner18 from "../../../../images/banner-images/Banner-18.png";
import banner19 from "../../../../images/banner-images/Banner-19.png";
import banner20 from "../../../../images/banner-images/Banner-20.png";
import banner21 from "../../../../images/banner-images/Banner-21.png";
import banner22 from "../../../../images/banner-images/Banner-22.png";
import banner23 from "../../../../images/banner-images/Banner-23.png";
import banner24 from "../../../../images/banner-images/Banner-24.png";
import banner25 from "../../../../images/banner-images/Banner-25.png";
import banner26 from "../../../../images/banner-images/Banner-26.png";
import banner27 from "../../../../images/banner-images/Banner-27.png";
import banner28 from "../../../../images/banner-images/Banner-28.png";
import banner29 from "../../../../images/banner-images/Banner-29.png";
import banner30 from "../../../../images/banner-images/Banner-30.png";
import banner31 from "../../../../images/banner-images/Banner-31.png";
import banner32 from "../../../../images/banner-images/Banner-32.png";
import banner33 from "../../../../images/banner-images/Banner-33.png";
import banner34 from "../../../../images/banner-images/Banner-34.png";
import banner35 from "../../../../images/banner-images/Banner-35.png";
import banner36 from "../../../../images/banner-images/Banner-36.png";
import banner37 from "../../../../images/banner-images/Banner-37.png";
import banner38 from "../../../../images/banner-images/Banner-38.png";
import banner39 from "../../../../images/banner-images/Banner-39.png";
import RecentlyBoughtBanner from "../../../banners/recently-bought"

const styles = require("./landing-page.module.scss")

type LandingPageBodyProps = {
  accountAddress: string | null
  contract: Contract
}

/**
 * Main page for CryptoCandies. Most of the site will be on this page
 */
const LandingPageBody: React.FC<LandingPageBodyProps> = ({ accountAddress, contract }) => {
  return (
    <>
      <Container className="image-banner" fluid>
        <Carousel
          swipeable={false}
          draggable={false}
          keyBoardControl={false}
          autoPlay={true}
          autoPlaySpeed={1}
          infinite={true}
          arrows={false}
          centerMode={false}
          containerClass="container-with-dots"
          customTransition="all 10s linear"
          // customTransition="transform 5s ease-in-out"
          transitionDuration={10000}
          focusOnSelect={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 15,
              partialVisibilityGutter: 40
            }
          }}
          showDots={false}
          slidesToSlide={10}
        >
          <Image src={banner1}/>
          <Image src={banner2}/>
          <Image src={banner3}/>
          <Image src={banner4}/>
          <Image src={banner39}/>
          <Image src={banner5}/>
          <Image src={banner6}/>
          <Image src={banner7}/>
          <Image src={banner8}/>
          <Image src={banner37}/>
          <Image src={banner9}/>
          <Image src={banner10}/>
          <Image src={banner11}/>
          <Image src={banner12}/>
          <Image src={banner13}/>
          <Image src={banner14}/>
          <Image src={banner15}/>
          <Image src={banner16}/>
          <Image src={banner17}/>
          <Image src={banner36}/>
          <Image src={banner18}/>
          <Image src={banner19}/>
          <Image src={banner20}/>
          <Image src={banner21}/>
          <Image src={banner22}/>
          <Image src={banner23}/>
          <Image src={banner24}/>
          <Image src={banner25}/>
          <Image src={banner26}/>
          <Image src={banner27}/>
          <Image src={banner28}/>
          <Image src={banner38}/>
          <Image src={banner29}/>
          <Image src={banner30}/>
          <Image src={banner31}/>
          <Image src={banner32}/>
          <Image src={banner33}/>
          <Image src={banner34}/>
          <Image src={banner35}/>
        </Carousel>
      </Container>
      <Container>
        <Row>
          <Col>
            <Clock contract={contract}/>
            <RecentlyBoughtBanner contract={contract}/>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <h1 className={styles.textHeader}>What are CryptoCandies?</h1>
            <p>CryptoCandies are the sweetest, sourest, and juiciest NFTs in the space. We have created 5000 totally unique collectible sweets, ranging from happy lollis to mustachioed gummy worms. No two candies are alike and each one can be owned on the Ethereum blockchain.</p>
            <Image className={"default-banner"} src={banner}/>
            <Image className={"mobile-banner"} src={sample2}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className={styles.textHeader}>Why get a Candy?</h1>
            <p>NFTs are the trading cards of the future. We, among others in the space, are reinventing collectibles as we know it. These cute lil’ treats act as non-edible tokens that you can showcase, trade, collect, and HODL. Additionally, who doesn’t love candy?!? Especially candy when it looks this cute 😍 So cave into your sweet tooth and get one of these NFTs before they’re all gone.</p>
            <p>Maybe you're nostalgic and like to remember the joy of having pops, gummies, and suckers as a treat when you were a child. Maybe you’re still a sugar fiend and like sweet treats or candy so sour that your lips zip up. Maybe you want to collect as many different NFTs as possible 😈 So many reasons and only 5000 CryptoCandies. We are excited to see what the community thinks of each and every treat!</p>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <h1 className={styles.textHeader}>Rarity</h1>
            <p>To make rarity as clear as possible we opted for fewer traits, and thus fewer editions than some of our peers. Rarity within CryptoCandies is defined by the specific features your Crypto Candy has. Each trait has variations that are common and variations that are extremely rare. Using our main trait, candy type, here is the breakdown of variants among the 5000 NFTs. </p>
            <Image className={"`styles.rarityTableImage` d-block mx-auto img-fluid w-75"} src={rarityTable} />
            <p>So our 🍬Rarest Candies 🍬are in fact, well, the rarest. And our fluffy Cotton Candies are the most abundant. Cotton Candy machine gone haywire, I guess.</p>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <h1 className={styles.textHeader}>Donations</h1>
            <p>If there is anything we like more than candy (and trust us we like candy) it is giving back in a meaningful way. That is why <b>20%</b> of the proceeds from this project, primary and secondary, will be donated to the nonprofits mentioned on our <a href="/donations" className="primary-color">donations page</a>.</p>
            <p>When a candy is purchased those funds will be routed to two separate wallets, one of which will be designated for donations. From this wallet we will send donations directly to organizations that accept Ether via <a href="https://www.thegivingblock.com/" target="_blank" className="primary-color">The Giving Block</a>. You can monitor that wallet <a href="https://etherscan.io/address/0x9B56B2d336fbB65477ee245D19C98cd43C77122B" target="_blank" className="primary-color">here</a> and see our impact!</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className={styles.textHeader}>How it Works?</h1>
            <p>CryptoCandies will launch a presale between April 16th and April 23rd. During this time, all CryptoCandies will be hidden 🥷, so there is no telling what you will get until the big reveal. Think of this like putting a quarter into the gumball machine - but better and with sweet, sweet NFTs. When the clock strikes 7PM ET on April 23rd you will be able to see which CryptoCandies you have 🦄 - just check out the <a href="/mycandies" className="primary-color">My Candies</a> tab.</p>
            <p>Each candy is a 1200 x 1200 PNG stored on an ERC-721 token and created with tender loving care. We mapped out all of the features before getting to work, and not to toot our own horn, but we think there is a great mix of traits that are rare and well... adorable.</p>
          </Col>
        </Row>
        <Row>
          {/* CryptoCandy to show on small screens */}
          <Col className="d-xs-block d-sm-block d-md-none d-lg-none d-xl-none">
            <Image src={sample1}/>
          </Col>

          {/* CryptoCandies to show on medium+ screens */}
          <Col md={4} lg={4} className="d-none d-md-block">
            <Image src={sample1}/>
          </Col>
          <Col md={4} lg={4} className="d-none d-md-block">
            <Image src={sample2}/>
          </Col>
          <Col md={4} lg={4} className="d-none d-md-block">
            <Image src={sample3}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>There will only be 5000 CryptoCandies available, with the pricing model below. We tried to design this model to be accessible and competitive with similar projects, while still rewarding our early adopters.</p>
            <ul className="pricing-model" style={{listStyle: "none"}}>
              <li>#0    - #19   Reserved for Planned Giveaways</li>
              <li>#20   - #49   @ 0.01Ξ</li>
              <li>#50   - #99   @ 0.02Ξ</li>
              <li>#100  - #499  @ 0.05Ξ</li>
              <li>#500  - #1249 @ 0.1Ξ</li>
              <li>#1250 - #3249 @ 0.2Ξ</li>
              <li>#3250 - #4249 @ 0.4Ξ</li>
              <li>#4250 - #4949 @ 0.6Ξ</li>
              <li>#4950 - #4999 @ 1.0Ξ</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className={styles.textHeader}>About Us</h1>
            <p>We are a small group of childhood friends that have known each other well before there ever was a blockchain.  We all strongly believe in the future of NFTs and have been long-time investors in various crypto projects. We're so excited for you to join us as we make our first contribution to the space with CryptoCandies.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className={styles.textHeader}>FAQ</h1>
            <p className={styles.textQuestion}>First time buyer?</p>
            <p>The first step would be setting up your very own wallet using the <a href="https://metamask.io/" target="_blank" className="primary-color">metamask plugin</a> on your browser.</p>
            <p>Once that is done, you're going to need some sweet, sweet ETH. You can either buy this directly through metamask, or via an exchange (we recommend  <a href="https://www.gemini.com/share/vkz9wzh8" target="_blank" className="primary-color">Gemini</a> but <a href="https://www.coinbase.com/" target="_blank" className="primary-color">Coinbase</a> is another option as well).</p>
            <p>With your ETH in hand, you are going to want to withdraw to your metamask address (if you purchased on an exchange). Then connect your wallet to our site using the option in the navigation bar.</p>
            <p>Voilà! Just like that you are now ready to buy some CryptoCandies  - and may we say we are honored to have been your first foray into this wonderful world 😋</p>
            <p>If you have any trouble please message the 🧐support🧐 channel in our Discord server and we will be more than happy to lend a hand.</p>
            <p className={styles.textQuestion}>How can I buy, sell, or trade my Candy?</p>
            <p>CryptoCandies are stored on ERC-721 tokens, so you can buy, sell, and trade them on popular platforms like <a href="https://opensea.io/" target="_blank" className="primary-color">OpenSea</a>.</p>
            <p className={styles.textQuestion}>Can I purchase CryptoCandies on a mobile device?</p>
            <p>No. We only support purchasing on desktop devices via <a href="https://metamask.io/" target="_blank" className="primary-color">metamask</a>.</p>
          </Col>
        </Row>
      </Container>
      <br/>
    </>
  )
}
export default LandingPageBody
