import React, { useEffect, useState } from "react"
import axios from "axios"
import { Contract } from "web3-eth-contract"
import { Image } from "react-bootstrap"

type ClockProps = {
    contract: Contract
}

const textStyle = {
    textAlign: "center",
    marginTop: "1.45rem"
}
const RecentlyBoughtBanner: React.FC<ClockProps> = ({ contract }) => {
    // Contract state
    const [metadata, setMetadata] = useState<Object>({})

    useEffect(() => {
        if (contract) {
            contract.methods.totalSupply().call().then(async (supply: number) => {
                const tokenUri = await contract.methods.tokenURI(supply - 1).call();
                const { data: metadata } = await axios.get(tokenUri)
                const ownerAddress = await contract.methods.ownerOf(supply - 1).call();
                const { data: openseaData } = await axios.get(`https://api.opensea.io/api/v1/assets?order_direction=desc&offset=0&limit=1&asset_contract_address=0x28b8cc2ad2dde11768b3e98e80310374a8d8407d&token_ids=${supply - 1}`)
                const { assets = [] } = openseaData
                if (assets.length === 1) {
                    const asset = assets[0]
                    const {
                        owner: {
                            user = {}
                        } = {}
                    } = asset
                    if (user == null) {
                        setMetadata({
                            ownerAddress,
                            username,
                            tokenId: supply - 1,
                            ...metadata
                        })

                    }
                    else {
                        const {
                            username = ""
                        } = user
                        setMetadata({
                            ownerAddress,
                            username,
                            tokenId: supply - 1,
                            ...metadata
                        })
                    }
                }
                else {
                    setMetadata({
                        ownerAddress,
                        tokenId: supply - 1,
                        ...metadata
                    })
                }
            })
        }
    }, [contract]);
    if (metadata.name === undefined) {
        return null
    }
    const {
        name,
        ownerAddress,
        username = "",
        image,
        tokenId = 0,
    } = metadata

    const openSeaCandyURL = `https://opensea.io/assets/0x28b8cc2ad2dde11768b3e98e80310374a8d8407d/${tokenId}`
    const openSeaUserURL = `https://opensea.io/accounts/${ownerAddress}`

    return (
        <div style={textStyle}>
            <Image src={image} 
                   width="160"
                   height="160"
            />
            <div> Most recently <a href={openSeaCandyURL} target="_blank" className="primary-color">{name}</a> was picked up by <a href={openSeaUserURL} target="_blank "className="primary-color">{username !== null && username !== "" ? username : ownerAddress}</a></div>
        </div>
    )
}

export default RecentlyBoughtBanner
