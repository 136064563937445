import React, { useEffect, useState } from "react"
import Countdown from 'react-countdown';
import { Contract } from "web3-eth-contract"
import { Container, Row, Col } from "react-bootstrap"

const PRESALE_TIME = '16 Apr 21 19:00:00 GMT'


type ClockProps = {
    contract: Contract
}
// TODO: style this clock
const Clock: React.FC<ClockProps> = ({ contract }) => {
    // Contract state
    const [currentSupply, setCurrentSupply] = useState<number>(0)
    const [supplyLimit, setSupplyLimit] = useState<number>(0)
    useEffect(() => {
        if (contract) {
            contract.methods.totalSupply().call().then((supply: number) => {
                setCurrentSupply(parseInt(supply))
            })
            contract.methods.CANDY_LIMIT().call().then((candyLimit: number) => {
                setSupplyLimit(parseInt(candyLimit))
            })
        }
    }, [contract, currentSupply]);

    const clockStyle = {
        textAlign: "center",
        marginTop: "1.45rem"
    }
    // Removed since we are post launch
    // const clockRenderer = ({ formatted }) => {
    //     const {
    //         days: daysString,
    //         hours: hoursString,
    //         minutes: minutesString,
    //         seconds: secondsString
    //     } = formatted
    //     const days = parseInt(daysString)
    //     const hours = parseInt(hoursString)
    //     const minutes = parseInt(minutesString)
    //     const seconds = parseInt(secondsString)
    //     if (days > 0) {
    //         const pluralDaysString = days == 1 ? "day" : "days"
    //         return (
    //             <>
    //                 <p style={clockStyle}>⏰ In <b className="clock-time">{days} {pluralDaysString}</b> CryptoCandies will be available for all! ⏰</p>
    //                 <p style={{ textAlign: "center" }}>Sale starts April 16th @ 3pm ET</p>
    //             </>
    //         )
    //     }
    //     if (hours > 0) {
    //         const pluralHoursString = hours == 1 ? "hour" : "hours"
    //         return (
    //             <>
    //                 <p style={clockStyle}>⏰ In <b className="clock-time"> {hours} {pluralHoursString}</b> you can get yourself some CryptoCandy! ⏰</p>
    //                 <p style={{ textAlign: "center" }}>Sale starts April 16th @ 3pm ET</p>
    //             </>
    //         )
    //     }
    //     if (minutes > 0) {
    //         const pluralMinutesString = minutes == 1 ? "minute" : "minutes"
    //         return (
    //             <>
    //                 <p style={clockStyle}>⏰ It's only a matter of minutes until CryptoCandies will be up for grabs - <b className="clock-time"> {minutes} {pluralMinutesString}</b> to be exact! ⏰</p>
    //                 <p style={{ textAlign: "center" }}>Sale starts April 16th @ 3pm ET</p>
    //             </>
    //         )
    //     }
    //     if (seconds > 0) {
    //         const pluralSecondsString = seconds == 1 ? "second" : "seconds"
    //         return (
    //             <>
    //                 <p style={clockStyle}>⏰ Only <b className="clock-time">{seconds} {pluralSecondsString}</b>... So close, you can almost taste it... ⏰</p>
    //                 <p style={{ textAlign: "center" }}>Sale starts April 16th @ 3pm ET</p>
    //             </>
    //         )
    //     }
    //     // Don't render anything once the countdown has ended
    //     if (currentSupply < supplyLimit) {
    //         return (
    //             <>
    //                 <p style={clockStyle}>🍬 {currentSupply}/{supplyLimit} CryptoCandies have been minted 🍬</p>
    //             </>
    //         )
    //     }
    //     // Handles case where not connected to metamask
    //     else if (supplyLimit <= 1) {
    //         return null
    //     }
    //     else {
    //         return <p style={clockStyle}>🍬 All CryptoCandies have been minted 🍬</p>
    //     }
    // }
    const renderSupplyMessage = () => {
         // Don't render anything once the countdown has ended
        if (currentSupply < supplyLimit) {
            return (
                <>
                    <p style={clockStyle}>🍬 {currentSupply} CryptoCandies have been minted 🍬</p>
                </>
            )
        }
        // Handles case where not connected to metamask
        else if (supplyLimit <= 1) {
            return null
        }
        else {
            return <p style={clockStyle}>🍬 All CryptoCandies have been minted 🍬</p>
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    {/* <Countdown date={Date.parse(PRESALE_TIME)} renderer={clockRenderer} /> */}
                    {renderSupplyMessage()}
                </Col>
            </Row>
        </Container>
    )
}

export default Clock
